<template>
  <div>

    <loading :loading="isLoading"></loading>
    <b-row class="" align-h="center">
      <b-col cols="12">
        <Card class="py-4 px-4 login-card">
          <template v-slot:content>
            <!--begin::Signin-->
            <div class='login-form login-signin'>
              <div class='text-center'>
                <div class="img logo purple"></div>
              </div>

              <p class="paragraph">{{ $t('AUTH.forgot_password_description') }}</p>

              <!--begin::Form-->
              <b-form class='form' @submit.stop.prevent='onSubmit'>

                <!-- <div
                  role='alert'
                  v-bind:class='{ show: errors.length }'
                  v-if="errors.length > 0"
                  class='alert fade alert-danger'
                >
                  <div class='alert-text' v-for='(error, i) in errors' :key='i'>
                    {{ error }}
                  </div>
                </div> -->

                <b-form-group
                  id='example-input-group-1'
                  :label="$t('FORMS.email')"
                  label-for='example-input-1'
                >
                  <b-form-input
                    class='form-control form-control-solid h-auto px-6'
                    id='example-input-1'
                    name='example-input-1'
                    v-model='$v.form.email.$model'
                    autocomplete="username"
                    :state="validateState('email')"
                    aria-describedby='input-1-live-feedback'
                  ></b-form-input>

                  <b-form-invalid-feedback id='input-1-live-feedback'>
                    {{ $t('AUTH.email_required') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--begin::Action-->
                <div
                  class='d-flex flex-wrap justify-content-between align-items-center text-center'
                >

                  <button
                    ref='kt_login_signin_submit'
                    class='btn btn-primary font-weight-bold px-9 my-3 font-size-3'
                    style="margin: 0 auto; padding-right: 32px; padding-left: 32px;"
                  >
                    {{ $t('ACTIONS.send') }}
                  </button>

                  <router-link
                    :to="{ name: 'login' }"
                    class='text-dark-60 text-hover-primary mr-2 w100p'
                    style="display: block; font-size: small; text-align: center;"
                  >
                  {{ $t('AUTH.already_know_your_password') }}
                  </router-link>
                </div>
                <!--end::Action-->
              </b-form>
              <!--end::Form-->
            </div>
            <!--end::Signin-->
          </template>
        </Card>
      </b-col>
    </b-row>
  </div>
</template>

<style lang='scss' scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

import ToastsMixin from '@/services/mixins/toasts.mixins'
import ErrorsMixin from '@/services/mixins/errors.mixins'

export default {
  mixins: [ToastsMixin, validationMixin, ErrorsMixin],
  name: 'ForgotPassword',
  data () {
    return {
      form: {
        email: ''
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    ...mapActions({
      submitForgotPassword: 'auth/forgotPassword'
    }),
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    resetForm () {
      this.form = {
        email: null,
        password: null
      }

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }

      const params = {
        email: this.$v.form.email.$model
      }

      this.submitForgotPassword(params)
        // go to which page after successfully login
        .then(() => {
          this.$router.push({ name: 'forgotPasswordSuccess' })
        })
        .catch(response => {
          if (typeof response.data === 'undefined' || typeof response.data.data === 'undefined') {
            this.showErrorToast(this.mapForgotPasswordError(''))
          } else {
            this.showErrorToast(this.mapForgotPasswordError(response.data.data))
          }
        })
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'auth/isLoading'
    })
  }
}
</script>
