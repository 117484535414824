import i18n from '@/plugins/vue-i18n'

export default {
  methods: {
    mapBackendRegisterError (err) {
      switch (err) {
        case 'student_signup.invalid_client_mail_domain': {
          return i18n.t('AUTH.ERRORS.domain_not_included_for_sign_up')
        }
        case 'Ya existe un estudiante con este email': {
          return i18n.t('AUTH.ERRORS.email_already_used')
        }
        case 'student_signup.blocked_mail_domain': {
          return i18n.t('AUTH.ERRORS.email_domain_blocked')
        }
        case 'security.register.invalid_register_intent_exception': {
          return i18n.t('AUTH.ERRORS.csrf_invalid')
        }
        case 'Este valor no es válido.': {
          return i18n.t('AUTH.ERRORS.invalid_value')
        }
        case 'Se ha alcanzado el número máximo de licencias permitido para este subcliente': {
          return i18n.t('AUTH.ERRORS.license_limit_reached')
        }
        default: {
          return i18n.t('AUTH.ERRORS.general_sign_up')
        }
      }
    },
    mapAccountActivationError (err) {
      switch (err) {
        case 'api.error.student.register.missing_student_with_token': {
          return i18n.t('AUTH.ERRORS.missing_student_with_token')
        }
        case 'errors.activate_account.user_is_not_pending_of_activation': {
          return i18n.t('AUTH.ERRORS.user_is_not_pending_of_activation')
        }
        default: {
          return i18n.t('AUTH.ERRORS.general_sign_up')
        }
      }
    },
    mapSetPasswordErrors (err) {
      switch (err) {
        case 'La contraseña es demasiado debil': {
          return i18n.t('AUTH.ERRORS.weak_password')
        }
        case 'errors.password_change.user_is_pending_of_activation': {
          return i18n.t('AUTH.ERRORS.pending_activation')
        }
        default: {
          return i18n.t('AUTH.ERRORS.general')
        }
      }
    },
    mapForgotPasswordError (err) {
      switch (err) {
        case 'errors.password_change.user_is_pending_of_activation': {
          return i18n.t('AUTH.ERRORS.pending_activation')
        }
        default: {
          return i18n.t('AUTH.ERRORS.general_sign_up')
        }
      }
    },
    mapRevertMachineError (err) {
      switch (err) {
        case 'vmware.max_reverts_per_student_and_day_matched': {
          return i18n.t('LAB_MACHINE.ERRORS.max_reverts_per_student_and_day_matched')
        }
        default: {
          return i18n.t('LAB_MACHINE.ERRORS.revert')
        }
      }
    },

    handleAxiosError (err) {
      if (typeof err.response === 'undefined') this.$router.push({ name: 'error', params: { error: 500 } })

      switch (err.response.status) {
        case 403: {
          this.$router.push({ name: 'error', params: { error: 403 } })
          break
        }
        case 404: {
          this.$router.push({ name: 'error', params: { error: 404 } })
          break
        }
        default: {
          this.$router.push({ name: 'error', params: { error: 500 } })
        }
      }
    }
  }
}
